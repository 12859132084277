<template>
  <b-modal
    v-model="visible"
    title="Editar Conta"
    cancel-variant="outline-secondary"
    centered
    @change="(val) => $emit('update:visible', val)"
  >
    <b-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label-size="md"
          label-align="left"
          label="Valor"
          label-for="defaultLabel"
        >
          <b-form-input
            id="defaultLabel"
            v-model="data.amount"
            class="w-100"
            placeholder=""
          />
        </b-form-group>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-button
        variant="relief-primary"
        :disabled="loading"
        block
        @click.stop="edit()"
      >
        <span v-if="!loading">Salvar</span>
        <b-spinner
          v-else
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BButton, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BSpinner
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BSpinner
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    async edit () {
      this.loading = true

      const url = `/admin/picpay/update/${this.data.id}`
      const params = {
        amount: this.data.amount
      }

      await this.$http.patch(url, params)
        .then(() => {
          this.$swal({
            title: 'Modificado com sucesso!',
            icon: 'success',
            confirmButtonText: 'Fechar',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .finally(() => {
          this.loading = false
          this.$emit('updated', this.data)
          this.$emit('update:visible', false)
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
