<template>
  <section>
    <b-card>
      <TablePagination
        v-model="items"
        url="/admin/picpay/all/paginate"
        :per-page="10"
        :fields="fields"
      >
        <template v-slot:name="data">
          {{ data.items.user.email }}
        </template>

        <template v-slot:amount="data">
          <b-button
            variant="relief-primary"
            size="sm"
            @click.stop="edit(data.items)"
          >
            {{ data.items.amount | currency }}
          </b-button>
        </template>

        <template v-slot:receipt="data">
          <b-button
            variant="relief-primary"
            size="sm"
            @click.stop="openReceipt(data.items)"
          >
            Ver comprovante
          </b-button>
        </template>

        <template v-slot:actions="data">
          <div v-if="data.items.status < 3">
            <b-button
              class="mr-1"
              size="sm"
              variant="relief-success"
              :disabled="loading"
              @click.stop="setStatus(data.items, 4)"
            >
              <feather-icon
                v-if="!loading"
                icon="CheckIcon"
              />
              <b-spinner
                v-else
                small
              />
            </b-button>

            <b-button
              size="sm"
              variant="relief-danger"
              :disabled="loading"
              @click.stop="setStatus(data.items, 3)"
            >
              <feather-icon
                v-if="!loading"
                icon="XCircleIcon"
              />
              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>

          <div v-if="data.items.status >= 3">
            <b-button
              size="sm"
              :disabled="loading"
              :variant="getStatus(data.items.status).color"
            >
              <span v-if="!loading">{{ getStatus(data.items.status).message }}</span>
              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>
        </template>

        <template v-slot:created_at="data">
          <b-button
            size="sm"
            variant="relief-primary"
          >
            {{ data.items.created_at | date }}
          </b-button>
        </template>
      </TablePagination>
    </b-card>

    <Edit
      v-if="editModal"
      v-model="editModal"
      :data="editData"
      @updated="updated($event)"
    />
  </section>
</template>

<script>
import { BCard, BButton, BSpinner } from 'bootstrap-vue'
import moment from 'moment'
import Edit from './Edit.vue'
import TablePagination from '@/components/TablePagination/Index.vue'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    Edit,
    TablePagination
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 2)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    loading: false,
    editModal: false,
    editData: {},
    items: [],
    fields: [
      {
        key: 'created_at',
        label: 'Data'
      },
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'amount',
        label: 'Valor'
      },
      {
        key: 'receipt',
        label: 'Comprovante'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  }),
  methods: {
    edit (item) {
      this.editData = JSON.parse(JSON.stringify(item))
      this.editModal = true
    },
    updated (payload) {
      Object.assign(
        this.items.find(item => item.id === payload.id),
        payload
      )
    },
    getType (type) {
      const response = {
        message: '',
        color: ''
      }

      switch (type) {
      case 1:
        response.message = 'Saldo em Conta'
        response.color = 'relief-primary'
        break

      case 2:
        response.message = 'PIX'
        response.color = 'relief-primary'
        break

      default:
        response.message = 'Não definido'
        response.color = 'relief-primary'
      }

      return response
    },
    getStatus (status) {
      const response = {
        message: '',
        color: ''
      }

      switch (status) {
      case 3:
        response.message = 'Recusado'
        response.color = 'relief-danger'
        break

      case 4:
        response.message = 'Aprovado'
        response.color = 'relief-success'
        break

      default:
        response.message = 'Não definido'
        response.color = 'relief-primary'
      }

      return response
    },
    setStatus (item, status) {
      this.$bvModal
        .msgBoxConfirm('Tem certeza que deseja alterar o status?', {
          title: 'Confirmação',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelVariant: 'secondary',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.setStatusConfirmed(item, status)
          }
        })
    },
    setStatusConfirmed (item, status) {
      let url = ''
      this.loading = true

      switch (status) {
      case 3:
        url = `/admin/picpay/set_decline/${item.id}`
        break

      case 4:
        url = `/admin/picpay/set_approved/${item.id}`
        break

      default:
        url = ''
        break
      }

      this.$http.get(url).finally(() => {
        const index = this.items.findIndex(i => i.id === item.id)
        this.items[index].status = status
      }).finally(() => {
        this.loading = false
      })
    },
    openReceipt (item) {
      window.open(`${this.$api_host}${item.receipt}`)
    }
  }
}
</script>
